import Vue from 'vue'

import './registerServiceWorker'
import WeuiVue from 'weui-design-vue'
import 'weui-design-vue/lib/weui-design-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'
import store from './store'

import Cookies from 'js-cookie';
Vue.prototype.$cookies = Cookies;


Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://localhost/dev-api/';
axios.defaults.baseURL = 'https://www.mankanghui.com/prod-api/';
// axios.defaults.baseURL = 'http://localhost/prod-api/';

Vue.use(WeuiVue)
Vue.use(VueAxios, axios)

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  console.log(config);
  config.headers['token'] = Cookies.get('token');
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

// Vue Router 的导航守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '曼康会';
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
